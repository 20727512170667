import React from 'react'
import Header from '../header/Header'
import Productsinfo from '../productsinfo/Productsinfo'

const Products = () => {
  return (
    <div>
        <Header />
        <Productsinfo />
    </div>
  )
}

export default Products