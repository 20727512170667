import React, { useEffect, useState } from 'react'

import './salepage.css'

import bgImg from '../assets/tsBackground.png'

import plugin from '../assets/AurFull.png'
import { ReactSVG } from 'react-svg'
import AddToCartButton from '../assets/AddToCartButton.svg'
import AddToCartButtonPhone from '../assets/AddToCartButtonPhone.svg'
import TsSliders from '../assets/TSSliders2.svg'
import YoutubeEmbed from './YoutubeEmbed'
import ReactPlayer from 'react-player'
import { UserAuth } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

import { motion } from "framer-motion"

import vstimg from '../assets/VSTLogoTs.png'
import auimg from '../assets/AULogoTS.png'
import aaximg from '../assets/AAXLogoTS.png'

import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics';

import { db } from "../firebase"
import {
  doc,
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  orderBy,
  getDoc,
  serverTimestamp,
  FieldValue,
  arrayUnion,
  updateDoc
} from "firebase/firestore"


const Salepage = () => {
  const [alreadyOwnesProduct, setAlreadyOwnesProduct] = useState(false);
  const [allOwnedProducts, setAllOwnedProducts] = useState([]);
  const [foundUser, setFoundUser] = useState(false);

  const { user } = UserAuth();
  const navigate = useNavigate()

  const [product, setProduct] = useState(null);
  const [checkout, setCheckout] = useState(null);

  // Fetch the product when the component mounts.
  /*useEffect(() => {
    client.product.fetch('gid://shopify/Product/8423792836911')
      .then((fetchedProduct) => {
        setProduct(fetchedProduct);
        return client.checkout.create(); // return this promise to chain then
      })
      .then((newCheckout) => {
        setCheckout(newCheckout);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);*/

  function commenceToPurchase() {
    if (alreadyOwnesProduct) {

    } else {
      navigate(`/purchase/aurora/succesful`);
    }
  }

  const buyProduct = () => {
    /*const lineItemsToAdd = [
      {
        variantId: product.variants[0].id,
        quantity: 1,
      },
    ];
    client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((updatedCheckout) => {
      window.location.href = updatedCheckout.webUrl;
    });*/
    logEvent(analytics, "SalePage_Aurora_BuyNow");
    navigate('/checkout')
  };

  //fetch purchased products
  /*useEffect(() => {
    if (user && foundUser) {
      console.log("called");
      const q = query(collection(db, "purchasedProducts"), where("user", "==", user.uid));

      onSnapshot(q, (snapshot) => {
        setAllOwnedProducts(
          snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              viewing: false,
              ...doc.data(),
            };
          })
        );
      });
    }
  }, [user.uid]);*/



  /*useEffect(() => {
    if (user) {
      setFoundUser(true);
      console.log(user)
      
    }
  }, [user.uid])*/

  useEffect(() => {
    console.log(foundUser)
  }, [foundUser])




  useEffect(() => {
    const hasYon = allOwnedProducts.some((product) => product.name === "aurora");
    if (hasYon) {
      setAlreadyOwnesProduct(true);
    }
  }, [allOwnedProducts])

  useEffect(() => {

  }, [alreadyOwnesProduct])


  return (
    <div className='salepage__container'>
      <div className="salepage__img">
        {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
      </div>

      <div className="salepage__ontop">
        <motion.div className="salepage__prodimg"
          initial={{ opacity: 0, scale: 0.7 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}>
          {/* <img src={plugin} alt="Img" className='salepage__pluginimgsize' />*/}

          <ReactPlayer url={"videos/AuroraTestLoop2.mp4"} className='salepage__pluginimgsize'
            width='90%'

            loop={true}
            playing={true}
            muted={true}
            playsinline={true}
          />

        </motion.div>

        <div className="salepage__prodinfo">
          <div className="salepage__leftside">
            <motion.div className="salepage__title"
              initial={{ opacity: 0, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
            >
              Aurora
            </motion.div>

            <motion.div className="salepage__description"
              initial={{ opacity: 0, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6 }}>
              Introducing the Aurora Tape Delay,
              a true audio enchanter that will breathe new life into your tracks.
            </motion.div>
          </div>

          <motion.div className="salepage__sliders"
            initial={{ opacity: 0, scale: 0.7 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 1.5 }}>
            <ReactSVG src={TsSliders}
              className="salepage__sliderssize"
            />
          </motion.div>

          <div className="salepage__rightside">
            <motion.div className="salepage__titleright"
              initial={{ opacity: 0, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}>
              Tape Delay
            </motion.div>

            <motion.div className="salepage__addtocartbutton"
              onClick={buyProduct}
              initial={{ opacity: 0, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.8 }}
              style={{ transform: `translate(200px, 0px)` }}
            >
              <div className="salepage__buttontext"
              >
                {alreadyOwnesProduct ? "Owned" : "Buy Now"}
              </div>

              <ReactSVG src={AddToCartButton} className="addtocartsize"

              />

            </motion.div>





            <motion.div className="salepage__price"
              initial={{ opacity: 0, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 1 }}>
              $39.99
            </motion.div>


            <motion.div className="salepage__addtocartbuttonphone"
              onClick={buyProduct}
              initial={{ opacity: 0, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ delay: 0.8 }}
              style={{ transform: `translate(200px, 0px)` }}>
              <div className="salepage__buttontext"
              >
                {alreadyOwnesProduct ? "Owned" : "Buy Now"}
              </div>

              <ReactSVG src={AddToCartButtonPhone} className="addtocartsize"

              />

            </motion.div>


          </div>
        </div>

        <div className="salepage__video">

          <YoutubeEmbed embedId="dQw4w9WgXcQ?si=zK-y8SEatQm6-InJ"

          />
        </div>

        <div className="salepage__moreinfo">
          <div className="salepage__infobox">
            <div className="salepage__infoboxtitle">
              Requirements
            </div>

            <div className="salepage__infoboxtext">
              Windows
              Windows 11, 10, 8, 7 or Vista
              64-bit or 32-bit
              VST 2/3 host or Pro Tools
              macOS
              macOS 10.13 or higher (64-bit only)
              AU or VST 2/3 host or Pro Tools
              Apple Silicon or Intel processor
              <br />
              <br />
              The computer must be connected to the internet at the time of product registration; however, subsequent to the completion of registration, the software is designed to function offline.

            </div>
          </div>

          <div className="salepage__infobox">
            <div className="salepage__infoboxtitle">
              Plug-in formats
            </div>

            <div className="salepage__infoboxtext">
              Tape Signal plug-ins are available
              in VST, VST3, Audio Units, AAX Native
              and AudioSuite formats.
            </div>

            <div className="salepage__formatimgs">
              <img src={vstimg} />
              <img src={auimg} />
              <img src={aaximg} />
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default Salepage