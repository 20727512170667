import React, { useEffect, useState } from 'react'
import bgImg from '../assets/AccountGradientBG.png'
import { UserAuth } from '../context/AuthContext'

import { db } from "../firebase"
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    deleteDoc,
    query,
    where,
    getDocs,
    orderBy,
    getDoc,
    serverTimestamp,
    FieldValue,
    arrayUnion,
    updateDoc
} from "firebase/firestore"


import { useMediaQuery } from 'react-responsive';

import './account.css'
import OnePurchase from './OnePurchase'

const Account = () => {
    const buttonsData = [
        "Purchase History",
        "Downloads",
        "Password and Security",
        "Account Settings"
    ];
    const [allOwnedProducts, setAllOwnedProducts] = useState([]);
    const [selected, setSelected] = useState(buttonsData[0]);


    const { user, deleteUser} = UserAuth();

    const handleDelete = () => {
        if (window.confirm("Are you sure you want to delete your account? This action is irreversible.")) {
            deleteAccount();
        }
    };


    const Buttons = () => {
        return (
            <div className="account__buttonscontainer">
                {buttonsData.map((button, index) => (
                    <button
                        key={index}
                        className={button === selected ? 'selected' : ''}
                        onClick={() => setSelected(button)}
                    >
                        {button}
                    </button>
                ))}
            </div>
        );
    };

    const Dropdown = () => (
        <select
            className="dropdown"
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
        >
            {buttonsData.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </select>
    );

    const ResponsiveButtons = () => {
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

        return isSmallScreen ? <Dropdown /> : <Buttons />;
    };


    //fetch purchased products
    useEffect(() => {
        if (user.uid) {
            const q = query(collection(db, "purchasedProducts"), where("user", "==", user.uid));

            onSnapshot(q, (snapshot) => {

                setAllOwnedProducts(snapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        viewing: false,
                        ...doc.data()
                    }
                }))
            })
        }
    }, [user.uid])

    useEffect(() => {
        console.log(selected)
    }, [selected])

    return (
        <div className='signup__container'>
            <div className="account__bg">
                {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>

            <div className="account__ontop">
                <div className='account__title'>
                    {user.email}
                </div>

                <ResponsiveButtons />

                {selected === "Purchase History" && (
                    allOwnedProducts.map((element, index) => (
                        <div key={element.id}>
                            <OnePurchase name={element.name} id={element.id} time={element.time} />
                        </div>
                    ))
                )}

                {selected === "Account Settings" && (
                    <button onClick={handleDelete}>
                        Delete Account
                    </button>
                )}
            </div>
        </div>
    )
}

export default Account