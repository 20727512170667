import React, { useState } from 'react'

import plugin from '../assets/AuroraSalePic4.png'

import { motion } from "framer-motion"

const OneProduct = (props) => {
    const [mouseEnter, setMouseEnter] = useState(false);
    return (
        <div>
            <motion.div className="products__itemcontainer"
                animate={{ scale: mouseEnter ? 1.02 : 1.0 }}
                onMouseEnter={e => {
                    setMouseEnter(true);
                }}
                onMouseLeave={e => {
                    setMouseEnter(false);
                }}

            >


                {/* <div className="products__item">
                    Aurora
            </div>*/}

                <motion.div className="products__prodimg"
                >
                    <img src={props.plugin} alt="Img" className={props.size} />
                    <motion.div
                        animate={{ opacity: mouseEnter ? 1.0 : 0.0 }}
                        className='product__blur'
                    >
                        <div className="product__title">
                            {props.name}
                        </div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </div>
    )
}

export default OneProduct