import React from 'react'
import Header from '../header/Header'

const TermsAndConditions = () => {
    return (
        <div className='salepage__container'>
            <div className="salepage__img">
            </div>
            <Header page="terms" />

        </div>
    )
}

export default TermsAndConditions