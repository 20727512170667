import React from 'react'

import bgImg from '../assets/tsBackground.png'

import './products.css'

import { useNavigate } from 'react-router-dom'
import OneProduct from './OneProduct'

import plugin from '../assets/AuroraSalePic4.png'
import pluginChorus from '../assets/70sForWeb.png'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebase'

const Productsinfo = () => {
    const navigate = useNavigate()

    function navigateToAurora() {
        logEvent(analytics, "Products_Aurora");
        navigate('/aurora');
    }

    function navigateToChorus() {
        logEvent(analytics, "Products_Chorus");
        navigate('/chorus');
    }

    return (
        <div>
            <div className="salepage__img">
                <img src={bgImg} alt="img" className='salepage__imgsize' />
            </div>

            <div className="item__aurora">
                <div onClick={navigateToAurora}>
                    <OneProduct plugin={plugin} size={"products__pluginimgsize"} name="Aurora" />
                </div>
            </div>
        </div>
    )
}

export default Productsinfo