import React, { useEffect } from 'react';
import Header from '../header/Header';

const PrivacyPolicy = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cdn.iubenda.com/iubenda.js";
        script.async = true;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <Header />
            <div className="salepage__img">
                {/*<img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>
            <div className="support__container">
                <div className="support__contentcontainer">
                    <div className='support__title'>Privacy Policy</div>
                    <a 
                        href="https://www.iubenda.com/privacy-policy/75381920/cookie-policy" 
                        className="iubenda-white iubenda-noiframe iubenda-embed iub-no-markup iubenda-noiframe iub-body-embed" 
                        title="Cookie Policy">
                        Cookie Policy
                    </a>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
