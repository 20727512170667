import React, { useEffect, useState } from 'react';
import CountrySelect from './CountrySelect';
import { UserAuth } from '../context/AuthContext'; // Adjust this import path accordingly
import StateSelect from './StateSelect';

const Billingdetails = (props) => {
    const initialState = {
        name: '',
        address: '',
        city: '',
        zipCode: '',
        termsAgreed: false
    };

    const [formData, setFormData] = useState(initialState);

    const { locationData } = UserAuth();
    //console.log(locationData)

    const { stateData, setUserState } = UserAuth();
    //console.log(stateData);

    useEffect(() => {
        if (locationData.countryCode != 'US') {
            setUserState('', '');
        }
    }, [locationData])

    useEffect(() => {
        props.setBillingDetails({ ...formData, formFilled: isFormFilled() });
    }, [formData]);

    const isFormFilled = () => {
        return Object.values(formData).every((field) => Boolean(field));
    };

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleCheckboxChange = () => {
        setFormData({
            ...formData,
            termsAgreed: !formData.termsAgreed
        });
    };

    return (
        <div className='billing__container'>
            <div className="billing__title">
                Billing Details
            </div>
            <div className="billing__inputs">
                <input
                    name="name"
                    placeholder='Name'
                    className='billing__input'
                    value={formData.name}
                    onChange={handleInputChange}
                ></input>

                <CountrySelect />

                {locationData.countryCode == 'US' && (
                    <StateSelect />
                )}

                <input
                    name="address"
                    placeholder='Address'
                    className='billing__input'
                    value={formData.address}
                    onChange={handleInputChange}
                ></input>

                <input
                    name='city'
                    placeholder='City'
                    className='billing__input'
                    value={formData.city}
                    onChange={handleInputChange}
                ></input>

                <input
                name='zipCode'
                    placeholder='Zip Code'
                    className='billing__input'
                    value={formData.zipCode}
                    onChange={handleInputChange}
                ></input>
            </div>

            <div className="billing__terms">
                <div className="billing__checkbox">
                    <input type="checkbox"
                        className='billing__checkboxstyle'
                        checked={formData.termsAgreed} 
                        onChange={handleCheckboxChange}  />
                </div>

                <div className="billing__termstext">
                    <div>
                        I agree to the&nbsp;
                    </div>

                    <div className="billing__termsunderlined">
                        Terms and Conditions
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Billingdetails;
