
import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom';

const CompletedReload = () => {
  let params = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    if (params.reload == 1) {
      navigate(`/aurora/lic/${2}/${params.machineid}/${params.useremail}`);
      window.location.reload();
    }
  }, [])

  return (
    <div className="salepage__img">
      {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
    </div>
  )
}

export default CompletedReload