import Client from 'shopify-buy';

const client = Client.buildClient({
    storefrontAccessToken: 'a0e42c6dc842950fceec3f9bd635f9d7',
    domain: 'tapesignal.myshopify.com',
});

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Product() {
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [checkout, setCheckout] = useState(null);

    // Fetch the product when the component mounts.
    useEffect(() => {
        client.product.fetch('gid://shopify/Product/8423792836911')
            .then((fetchedProduct) => {
                setProduct(fetchedProduct);
                return client.checkout.create(); // return this promise to chain then
            })
            .then((newCheckout) => {
                setCheckout(newCheckout);
            })
            .catch((error) => {
                console.error("An error occurred:", error);
            });
    }, []);


    const buyProduct = () => {
        const lineItemsToAdd = [
            {
                variantId: product.variants[0].id,
                quantity: 1,
            },
        ];
        client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((updatedCheckout) => {
            window.location.href = updatedCheckout.webUrl;
        });
    };

    return (
        <div>
            {product && (
                <div>
                    <h1>{product.title}</h1>
                    <p>{product.description}</p>
                    <button onClick={buyProduct}>Buy</button>
                </div>
            )}
        </div>
    );
}

export default Product;
