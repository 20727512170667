import React, { useEffect, useState } from 'react'

import bgImg from '../assets/AccountGradientBG.png'
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { UserAuth } from '../context/AuthContext'

const SignIn = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [buttonVisible, setButtonVisible] = useState(true);
    const [mouseEnter, setMouseEnter] = useState(false);
    const navigate = useNavigate()
    let isPlugin = props.isPlugin;
    if (isPlugin !== false) {
        isPlugin = true;
    }

    console.log(isPlugin)

    const auth = getAuth();
    const { signIn, user } = UserAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await signIn(email, password);
            signInAndNavigate();
        } catch (error) {
            let friendlyMessage;
            switch (error.code) {
                case 'auth/wrong-password':
                    friendlyMessage = "Oops! That wasn't the right password. Please check and try again.";
                    break;
                case 'auth/user-not-found':
                    friendlyMessage = "We can't seem to find an account with that email.";
                    break;
                case 'auth/invalid-email':
                    friendlyMessage = "Oops, that doesn't look like a valid email address.";
                    break;
                default:
                    friendlyMessage = "Something went wrong. Please try again later.";
                    break;
            }
            setError(friendlyMessage);
            console.log(friendlyMessage);
        }
    }


    useEffect(() => {
        if (password && email && email.includes('@')) {
            setButtonVisible(true);
        }
        else {
            setButtonVisible(false);
        }
    }, [email])

    useEffect(() => {
        if (password && email && email.includes('@')) {
            setButtonVisible(true);
        }
        else {
            setButtonVisible(false);
        }
    }, [password])

    const signInAndNavigate = () => {
        if (isPlugin) {
            navigate('/aurora/create/');
        }

        else {
            navigate('/aurora');
        }
    }

    return (
        <div className='signup__container'>
            <div className="salepage__img">
                {/*<img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>
            <div className="salepage__ontop">
                {isPlugin && <div className="signin__plugingap"></div>}
                <motion.div className="signup__infocontainer"
                    initial={{ opacity: 0, scale: 0.7 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}>
                    <div className='signup__headertext'>
                        Sign In To Your Account
                    </div>
                    <form className="signup__inputcontainer" onSubmit={handleSubmit}>
                        <input className='signup__input'
                            type="email"
                            placeholder='Email'
                            onChange={(e) => setEmail(e.target.value)}
                            required>
                        </input>
                        <input className='signup__input'
                            type="password"
                            placeholder='Password'
                            onChange={(e) => setPassword(e.target.value)}
                            required>
                        </input>
                        {buttonVisible &&
                            (<motion.div className='signup__button'
                                animate={{
                                    scale: 1,
                                    opacity: buttonVisible ? 1 : 0.5,
                                    x: mouseEnter ? 2 : 0
                                }}
                                onClick={handleSubmit}
                                onMouseEnter={() => setMouseEnter(true)}
                                onMouseLeave={() => setMouseEnter(false)}
                                whileHover={{ scale: buttonVisible ? 1.05 : 1 }}
                                whileTap={{ scale: buttonVisible ? 0.95 : 1 }}>
                                Sign In
                                <button className='hiddenbutton'></button>
                            </motion.div>)}
                        {!buttonVisible && !isPlugin && <div>
                            <div className="signin__already" onClick={() => { navigate('/reset') }}>
                                Forgot Password
                            </div>
                            <div className="signin__already" onClick={() => { navigate('/signup/aurora') }}>
                                Create an account
                            </div>
                        </div>}
                        <div className='error__message__signin'>{error}</div>
                    </form>
                    <div className='test__useremailsignin'></div>
                </motion.div>
            </div>
        </div>
    )
}

export default SignIn