import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'


import { db } from "../firebase"
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    deleteDoc,
    query,
    where,
    getDocs,
    orderBy,
    getDoc,
    serverTimestamp,
    FieldValue,
    arrayUnion,
    updateDoc
} from "firebase/firestore"

import { UserAuth } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

const PurchaseRedirect = () => {
    //býr til license í db og redirectar á thanks

    let params = useParams();

    const { user } = UserAuth();
    const navigate = useNavigate();

    const purchaseCollectionRef = collection(db, "purchasedProducts");

    const addNewPurchase = () => {

        const newProductSale = {
            name: params.name,
            user: user.uid,
            useremail: user.email,
            time: serverTimestamp(),
            license1: "",
            license2: ""
        }


        setDoc(doc(purchaseCollectionRef), newProductSale).then((value) => {
            navigate('/thanksforpurchase/${params.name}');
        });

    };


    useEffect(() => {
        addNewPurchase();
    }, [])



    return (
        <div>
            <div className="loadingOverlay">
                <div className="spinner"></div>
            </div></div>
    )
}

export default PurchaseRedirect