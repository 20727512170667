import React from 'react'
import Header from '../header/Header'
import SignUp from './SignUp'

const SignUpPage = () => {
    return (
        <div>
            <Header />
            <SignUp />
        </div>
    )
}

export default SignUpPage