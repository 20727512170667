import React from 'react'
import Header from '../header/Header'
import Salepage from '../salepage/Salepage'

const Aurora = () => {
    return (
        <div>
            <Header />
            <Salepage />
        </div>
    )
}

export default Aurora