import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword, signInWithEmailAndPassword,
    onAuthStateChanged, getAuth, setPersistence, browserLocalPersistence,
    deleteUser, sendEmailVerification, sendPasswordResetEmail, confirmPasswordReset
} from 'firebase/auth'
import { auth as firebaseAuth } from "../firebase";

const UserContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});

    const [locationData, setLocationData] = useState({ countryName: '', countryCode: '' });
    const [stateData, setStateData] = useState({ stateName: '', stateCode: '' });
    const [currency, setCurrency] = useState({});

    function getElementText(response, elementName) {
        return response.getElementsByTagName(elementName)[0].innerHTML;
    }

    useEffect(() => {
        fetch('http://api.hostip.info').then(response => {
            return response.text();
        }).then(xml => {
            const xmlDoc = (new window.DOMParser()).parseFromString(xml, "text/xml");
            const countryName = getElementText(xmlDoc, "countryName");
            const countryCode = getElementText(xmlDoc, "countryAbbrev");

            setLocationData({ countryName, countryCode });
        });
    }, [])

    useEffect(() => {

    }, [locationData])

    useEffect(() => {
        const auth = getAuth();
        setPersistence(auth, browserLocalPersistence)
            .then(() => {
                const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
                    //console.log('Auth state changed: ', currentUser);
                    setUser(currentUser);
                });
                return () => {
                    unsubscribe();
                };
            });
    }, []);

    const createUser = (email, password) => {
        const auth = getAuth();
        return createUserWithEmailAndPassword(auth, email, password)
    };

    const signIn = (email, password) => {
        const auth = getAuth();
        return signInWithEmailAndPassword(auth, email, password)
    }

    const setUserLocation = (countryName, countryCode, currency) => {
        setLocationData({ countryName, countryCode });
        setCurrency({ currency });
    }

    const setUserState = (stateName, stateCode) => {
        setStateData({ stateName, stateCode });
    }

    const deleteAccount = () => {
        const auth = getAuth();
        if (auth.currentUser) {
            deleteUser(auth.currentUser).then(() => {
                console.log("User account deleted successfully.");
            }).catch((error) => {
                console.error("Error deleting user: ", error);
            });
        }
    }

    const resetPassword = (email) => {
        const auth = getAuth();
        /*   return sendPasswordResetEmail(auth, email, {
                url: `http://www.tapesignal.com/login`,
            })*/
        return sendPasswordResetEmail(auth, email)
    };

    function resetPasswordField(oobCode, newPassword) {
        return confirmPasswordReset(auth, oobCode, newPassword)
    }

    const verifyEmail = () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            return sendEmailVerification(currentUser, {
                url: "https://www.tapesignal.com/aurora",
            });

        }
    };




    return (
        <UserContext.Provider value={{ createUser, signIn, user, locationData, setUserLocation, setUserState, stateData, deleteAccount, currency, resetPassword, verifyEmail, resetPasswordField }}>
            {children}
        </UserContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(UserContext)
}
