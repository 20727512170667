import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import { UserAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'

const ResetPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate()

    const { resetPassword } = UserAuth();

    const handleReset = async (event) => {
        event.preventDefault();
        try {
            await resetPassword(email);
            navigate('/resetinfo');
        } catch (e) {
            if (e.message.includes("user-not-found")) {
                setError("User Not Found - Make Sure You Typed Your Email Correctly");
            }
        }
    }


    return (
        <div>
            <Header />
            <div className="salepage__img">
                {/*<img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>

            <div className="reset__gap">

            </div>

            <div className="support__container">
                <div className="support__contentcontainer">
                    <div className='support__title'>Reset Password</div>
                    <form onSubmit={handleReset} className='contact__formcontainer'>
                        <label>Email</label>
                        <input type="email" name="email" onChange={(e) => { setEmail(e.target.value) }} />
                        {error !== "" &&(<div className="reset__error">
                            {error}
                        </div>)}
                        < button type="submit">Reset Password</button>
                    </form>
                </div>

            </div>

        </div >
    )
}

export default ResetPassword