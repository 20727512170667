import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom';

const NewLicRefresh = () => {
  let params = useParams();
  const navigate = useNavigate()

  console.log(params.numlic)

  useEffect(() => {
    console.log(params.reload);
    if (params.reload == 1) {
      navigate(`/aurora/create/${2}/${params.licenseid}/${params.numlic}`);
      window.location.reload();
    }
  }, [])

  return (
    <div className="salepage__img">
      {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
    </div>

  )
}

export default NewLicRefresh