import React from 'react'
import Header from '../header/Header'
import './about.css'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'

import bgimg from '../assets/AboutImage.jpg'
import { motion, AnimateSharedLayout } from 'framer-motion';

import { analytics } from '../firebase'
import { logEvent } from 'firebase/analytics';

import {
    serverTimestamp
} from "firebase/firestore"

const AboutPage = () => {
    const navigate = useNavigate()
    const { user } = UserAuth()

    const changeToContact = () => {
        logEvent(analytics, "About_Contact")
        navigate('/contact');
    }

    const changeToPrivacy = () => {
        logEvent(analytics, "About_Privacy")
        navigate('/privacypolicy');
    }

    const timestamp = serverTimestamp();
    console.log(timestamp);

    

    return (
        <motion.div
            initial={{ opacity: 0.9, scale: 1 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.0 }}
            className="about-screen">

            <motion.div
                initial={{ opacity: 0, scale: 1, y: -300 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1 }}
                className="about__img">
                <div className='about__gradient'>

                </div>
                <img src={bgimg}
                    className='about__imgsize'
                >
                </img>
            </motion.div>

            {/*<div className="about-blurry"></div>*/}

            <Header page="about" />

            <div className="about__gap">

            </div>

            <div className="about__textcontent">
                <motion.div className="about__left"
                    initial={{ opacity: 0, scale: 1, y: -300 }}
                    whileInView={{ opacity: 1, scale: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.1 }}>
                    Tape Signal
                </motion.div>

             
                <motion.div
                initial={{ opacity: 0, scale: 1, x: 300 }}
                whileInView={{ opacity: 1, scale: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.1 }}
                className="about__right"
                >
                    <div className="about__infoleft">
                        <div className="about__info1">
                            We are Tape Signal, a new audio software company. We are determined to create the best sounding
                            modern plugin effects and help all creatives
                            take their sound to a new level.
                        </div>

                        <div className="about__info2">
                        Our first plugin, Aurora, is now available. Aurora is the delay effect that has been absent from everyone's plugin arsenal. It features two equalizers that operate at every feedback loop.
                        </div>
                    </div>

                    <div className="about__inforight">
                        If you have any questions or requests please feel
                        free to email us at support@tapesignal.com

                        Best regards,
                        Tape Signal
                    </div>
                </motion.div>
            </div>

            <div className="bottom-left-about">Tape Signal ehf.</div>
            <div className="bottom-right-about">
                <div onClick={() => {changeToPrivacy()}}>
                    Privacy Policy.
                </div>

                <div onClick={() => { changeToContact() }}>
                    Contact us.
                </div>
            </div>


        </motion.div>

    )
}

export default AboutPage

