import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import PaymentComponent from '../testpages/TestShopRapyd2'
import { useParams } from 'react-router-dom'
import './checkoutpage.css'
import OneCartItem from './OneCartItem'
import CheckoutTotal from './CheckoutTotal'
import CountrySelect from './CountrySelect'
import Billingdetails from './Billingdetails'
import { UserAuth } from '../context/AuthContext'; // Adjust this import path accordingly
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebase'

const CheckoutPage = () => {
    const [page, setPage] = useState(0);
    const [billingDetails, setBillingDetails] = useState({});
    const [data, setData] = useState(null)
    const [errorMessage, setErrorMessage] = useState("");

    const { locationData, stateData, currency } = UserAuth();
    const { user } = UserAuth();

    const params = useParams();

    const navigate = useNavigate();

    const nextPage = () => {
        if (page == 0 || page == 1) {
            if (billingDetails.formFilled && locationData.countryCode != '' || page == 0) {
                setPage(page + 1);
            }
        }
    }

    const prevPage = () => {
        if (page == 1 || page == 2) {
            setPage(page - 1);
        }
    }

    useEffect(() => {
        const sendRequest = (retryCount = 0) => {
            if (user && locationData) {
                if (locationData.countryCode !== "XX") {
                    fetch('https://rap-server-32a3v5w4sa-uc.a.run.app/', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            userId: user.uid,
                            country: locationData.countryCode,
                            time: 123,
                            email: user.email,
                            state: stateData.stateName,
                            name: billingDetails.name,
                            city: billingDetails.city,
                            address: billingDetails.address,
                            zipCode: billingDetails.zipCode,
                            currency: currency.currency
                        })
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then(data => {
                            console.log(data);
                            setData(data);
                        })
                        .catch(error => {
                            console.error('Error:', error);

                            if (retryCount < 3) {
                                console.log(`Retrying... attempt ${retryCount + 1}`);
                                sendRequest(retryCount + 1);
                            } else {
                                console.error('Max retries reached');
                            }
                        });
                }
            }
        }

        sendRequest();

    }, [locationData]);


    useEffect(() => {

    }, [billingDetails])

    const handlePaypalClick = () => {
        logEvent(analytics, "Rapyd_failed_paypal_option_selected")
        setPage(0);
    }

    return (
        <div>
            <Header />
            <div className="salepage__img">
                {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>


            <div className="checkout__contentcontainer">
                <div className="checkout__screen">

                    {/*   <div className="checkout__countryselector">
                        <CountrySelect />
    </div>*/}
                    {page === 0 && (
                        <div className="checkout__paymentcomponent">
                            {/* <div className="checkout__optiontitle">
                                Checkout via Rapyd
                            </div>
                            <div className="checkout__option"
                                onClick={() => {
                                    logEvent(analytics, "RapydPayment_Selected");
                                    nextPage();
                                }}>
                                Debit or Credit Card
                            </div>*/}

                            <div className="checkout__optiontitle">
                                Checkout via PayPal
                            </div>

                            <PayPalScriptProvider
                                //options={{ "client-id": "AbxmWGRh0N2_8-gGYvj0K2PR3kKvNpf-nTR74zyGGiRQx7Cj72QBwnIpoaK74RLlvB_eoDTAeKCBH75f" }}>
                                options={{ "client-id": "AZj3muinRRGJvp5uJE1DFGUDA5nHL4O2C7-0Aj-6eL_0ZypyE3bhnp2qIw9sIF62waIjiaU1Tk0VRaky" }}>
                                <PayPalButtons
                                    className='checkout__paypal'
                                    createOrder={() => {
                                        logEvent(analytics, "PayPal_selected")
                                        return fetch('https://ts-payment-l6tiifacuq-uc.a.run.app/create-order', {
                                            method: "POST",
                                            headers: {
                                                "Content-Type": 'application/json'
                                            },
                                            body: JSON.stringify({
                                                items: [
                                                    {
                                                        id: 1,
                                                        quantity: 1,
                                                    },
                                                ],
                                                userid: [user.uid]
                                            }),
                                        })
                                            .then(res => {
                                                if (res.ok) return res.json()
                                                return res.json().then(json => Promise.reject(json))
                                            })
                                            .then(({ id }) => {

                                                return id
                                            })
                                            .catch(e => {
                                                console.error(e.error)
                                            })
                                    }}
                                    onApprove={async (data, actions) => {
                                        try {
                                            const response = await fetch('https://ts-payment-l6tiifacuq-uc.a.run.app/capture-order', {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    orderId: data.orderID
                                                }),
                                            });

                                            if (!response.ok) {
                                                const errorData = await response.json();
                                                throw new Error(errorData.error);
                                            }

                                            const result = await response.json();
                                            navigate('/thanksforpurchase/aurora')
                                        } catch (error) {
                                            console.error('Error capturing order:', error);
                                            // Handle the error appropriately in the UI
                                        }
                                    }}
                                />
                            </PayPalScriptProvider>
                        </div>
                    )}


                    {page === 1 && (
                        <div className="checkout__paymentcomponent">

                            <div>
                                <Billingdetails setBillingDetails={setBillingDetails} />
                            </div>

                            <div className="checkout__nextpagebutton"
                                onClick={() => { nextPage() }}
                            >
                                Next Page
                            </div>

                        </div>
                    )}


                    {page === 2 && (
                        <div className='checkout__paymentcomponent'>
                            <PaymentComponent checkoutid={params.checkoutid} billingDetails={billingDetails} data={data}
                                errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
                            <div>
                                <div>
                                    {errorMessage}
                                </div>
                                <div
                                    className='checkout__trypaypal'
                                    style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                                    onClick={handlePaypalClick}
                                >
                                    Not Working? Try PayPal
                                </div>
                            </div>
                        </div>


                    )}


                    <div className="checkout__middleline"></div>

                    <div className="checkout__info">
                        <OneCartItem name="Aurora" price="39.99$" />

                        <div className="checkout__whitespace">

                        </div>

                        <CheckoutTotal price="39.99$" />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutPage