import React, { useEffect } from 'react'
import SignIn from './SignIn'
import { useNavigate, useParams } from 'react-router-dom';

const SignInPagePlugin = () => {
  let params = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    console.log(params.reload);
    if (params.reload == 1) {
      navigate(`/signin/plugin/2`);
      window.location.reload();
    }

    if (params.reload == 2) {
      navigate(`/signin/plugin`);
      
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      if (params.reload == 1) {
        navigate(`/signin/plugin/2`);
        window.location.reload();
      }
    }, 5000);

    // Clear interval on component unmount
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>

    </div>
  )
}

export default SignInPagePlugin