import React from 'react'
import Header from '../header/Header'

const ResetInfo = () => {
    return (
        <div>
            <Header />
            <div className="salepage__img" />

            <div className="reset__gap">

            </div>

            <div className="support__container">
                <div className="support__contentcontainer">
                    <div className='support__title'>
                        Check your email for instructions on how to reset your password.
                        <br></br>
                        P.S. the email might be found in your spam/junk mail.
                    </div>
                </div>
            </div>

        </div >
    )
}

export default ResetInfo