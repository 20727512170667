import React, { useState } from 'react';
import Header from '../header/Header';
import { UserAuth } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResetField = () => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const query = useQuery();

    const { resetPasswordField } = UserAuth();

    const handleReset = async (event) => {
        event.preventDefault();
        if (password === "") {
            setError("Password field cannot be empty");
            return;
        }
        try {
            await resetPasswordField(query.get('oobCode'), password);
            navigate('/aurora');
        } catch (e) {
            if (e.message.includes("user-not-found")) {
                setError("User Not Found - Make Sure You Typed Your Email Correctly");
            } else {
                setError("An error occurred. Please try again.");
            }
        }
    }

    return (
        <div>
            <Header />
            <div className="salepage__img">
                {/*<img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>

            <div className="reset__gap"></div>

            <div className="support__container">
                <div className="support__contentcontainer">
                    <div className='support__title'>Enter New Password</div>
                    <form onSubmit={handleReset} className='contact__formcontainer'>
                        <label>Password</label>
                        <input 
                            type="password" 
                            name="password" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} 
                        />
                        {error && (<div className="reset__error">
                            {error}
                        </div>)}
                        <button type="submit">Reset Password</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetField;
