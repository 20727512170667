import React from 'react'
import salepic from '../assets/AuroraSalePicSmall.png'

const OneCartItem = (props) => {
    return (
        <div className='cartitem__container'>
            <div className="cartitem__image">
                <img src={salepic} alt="img" className='cartitem__imagesize' />
            </div>

            <div className="cartitem__name">
                {props.name}
            </div>

            <div className="cartitem__price">
                {props.price}
            </div>
        </div>
    )
}

export default OneCartItem