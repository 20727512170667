import React from 'react'

import { useNavigate } from 'react-router-dom';

const OnePurchase = (props) => {
    const name = ' ' + props.name.charAt(0).toUpperCase() + props.name.slice(1);

    console.log(props.time)
    
    // Convert the Unix timestamp to a JavaScript Date object
    const date = new Date(props.time);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const navigate = useNavigate();

    // Create a formatted date string using the 'toLocaleString()' method with the desired options
    const formattedDate = date.toLocaleString('en-US', options);

    const goToDownloads = (name) =>{
        navigate('/thanksforpurchase/' + name)
    }

    return (
        <div className='onepurchase__container'>

            <div className="onepurchase__top">
                <div className="onepurchase__title">
                    {formattedDate}
                </div>

                <div className="onepurchase__button"
                onClick={() => {goToDownloads(name)}}
                >
                    Downloads
                </div>
            </div>

            <div className="onepurchase__line">
                
            </div>

            <div className="onepurchase__product">
                {name}
            </div>
        </div>
    )
}

export default OnePurchase
