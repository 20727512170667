import React from 'react'
import Header from '../header/Header'
import { UserAuth } from '../context/AuthContext'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import CookieConsent from '../components/CookieConsent'

const VerifyEmail = () => {
    const { verifyEmail } = UserAuth();

    const handleSendVerificationEmail = async () => {
        console.log("called")
        const currentUser = getAuth().currentUser;
        if (currentUser) {
            try {
                await verifyEmail();
            } catch (error) {
                console.error("Error sending verification email: ", error);
            }
        }
    };


    return (
        <div>
            <Header />
            <div className="salepage__img">
                {/*<img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>

            <div className="reset__gap">

            </div>
            <div className="reset__gap">

            </div>


            <div className="support__container">
                <div className="support__contentcontainer">
                    <div className='support__title'>Verify Your Email.</div>
                    <div className='support__title'>Didn't recieve a verification email?</div>
                    <div className="signin__already"
                        onClick={() => { handleSendVerificationEmail() }}
                    >
                        Resend Verification Email
                    </div>
                </div>

            </div>

        </div >
    )
}

export default VerifyEmail