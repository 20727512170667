// src/AdminPage.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import './admin.css';
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    deleteDoc,
    query,
    where,
    getDocs,
    orderBy,
    getDoc,
    serverTimestamp,
    FieldValue,
    arrayUnion,
    updateDoc
} from "firebase/firestore"

import { ReactSVG } from 'react-svg'
import TsLogo from '../assets/TSLogoWhite.svg'

const AdminPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [purchasedProducts, setPurchasedProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalProduct, setModalProduct] = useState({ id: '', field: '' });
    const [showNewLicenseModal, setShowNewLicenseModal] = useState(false);
    const [newLicenseForm, setNewLicenseForm] = useState({
        name: '',
        user: '',
        useremail: '',
    });


    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            if (searchTerm.trim() === '') {
                setPurchasedProducts([]);
                return;
            }

            const startWithSearchTerm = searchTerm.toLowerCase();
            const endWithSearchTerm = searchTerm.toLowerCase() + '\uf8ff';
            const q = query(
                collection(db, 'purchasedProducts'),
                where('useremail', '>=', startWithSearchTerm),
                where('useremail', '<=', endWithSearchTerm)
            );

            onSnapshot(q, (snapshot) => {
                setPurchasedProducts(
                    snapshot.docs.map((doc) => {
                        return {
                            id: doc.id,
                            viewing: false,
                            ...doc.data(),
                        };
                    })
                );
            });

            console.log(purchasedProducts);
        }
    };

    /*const clearLicenseField = async (productId, field) => {
        try {
            await updateDoc(doc(db, 'purchasedProducts', productId), {
                [field]: '',
            });
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };*/

    const clearLicenseField = (productId, field) => {
        setModalProduct({ id: productId, field });
        setShowModal(true);
    };

    const handleModalConfirm = async () => {
        try {
            await updateDoc(doc(db, 'purchasedProducts', modalProduct.id), {
                [modalProduct.field]: '',
            });
        } catch (error) {
            console.error('Error updating document:', error);
        }
        setShowModal(false);
    };

    const purchaseCollectionRef = collection(db, "purchasedProducts");

    const handleNewLicenseSubmit = async () => {
        const { name, user, useremail } = newLicenseForm;

        if (name && user && useremail) {
            try {
                const newLicense = {
                    name: name,
                    user: user,
                    useremail: useremail.toLowerCase(),
                    time: Date.now(),
                    license1: '',
                    license2: '',
                };

                setDoc(doc(purchaseCollectionRef), newLicense)

            } catch (error) {
                console.error('Error adding new license:', error);
            }
            setShowNewLicenseModal(false);
        }
    };



    return (
        <div className="admin__container">
            <div className="admin__logocontainer">
                <div className="admin__logo">
                    <ReactSVG fill='red' stroke='green' src={TsLogo} />
                </div>
                - Admin
            </div>

            <div className="admin__licmanager">
                <input
                    className="admin__input"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Search License by Email"
                />

                <div className="admin__manager">
                    {purchasedProducts.map((product) => (
                        <div key={product.id} className="admin__product">
                            <div>Product ID: {product.name}</div>
                            <div>Email: {product.useremail}</div>
                            <div>License 1: {product.license1}</div>
                            <button
                                className='admin__button'
                                onClick={() => clearLicenseField(product.id, 'license1')}>
                                Clear License 1
                            </button>
                            <div>License 2: {product.license2}</div>
                            <button
                                className='admin__button'
                                onClick={() => clearLicenseField(product.id, 'license2')}>
                                Clear License 2
                            </button>
                            {/* Add more fields as needed */}
                            <div className="admin__line"></div>
                        </div>

                    ))}
                </div>
                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Confirm Deletion</h2>
                            <p>Are you sure you want to delete this license?</p>
                            <button onClick={handleModalConfirm}>Yes</button>
                            <button onClick={() => setShowModal(false)}>No</button>
                        </div>
                    </div>
                )}
            </div>

            <button
                className="add-license-button"
                onClick={() => setShowNewLicenseModal(true)}
            >
                +
            </button>
            {showNewLicenseModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Create New License</h2>
                        <form>
                            <label>Product Name</label>
                            <input
                                className='admin__input'
                                type="text"
                                value={newLicenseForm.name}
                                onChange={(e) =>
                                    setNewLicenseForm({ ...newLicenseForm, name: e.target.value })
                                }
                            />
                            <label>User</label>
                            <input
                                className='admin__input'
                                type="text"
                                value={newLicenseForm.user}
                                onChange={(e) =>
                                    setNewLicenseForm({ ...newLicenseForm, user: e.target.value })
                                }
                            />
                            <label>User Email</label>
                            <input
                                className='admin__input'
                                type="email"
                                value={newLicenseForm.useremail}
                                onChange={(e) =>
                                    setNewLicenseForm({
                                        ...newLicenseForm,
                                        useremail: e.target.value,
                                    })
                                }
                            />
                        </form>
                        <button onClick={handleNewLicenseSubmit}>Create</button>
                        <button onClick={() => setShowNewLicenseModal(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminPage;
