import React, { useEffect, useState } from 'react'

import bgImg from '../assets/AccountGradientBG.png'
import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { UserAuth } from '../context/AuthContext'

import { db } from "../firebase"
import {
    collection,
    onSnapshot,
    query,
    where
} from "firebase/firestore"

import './licenses.css'

const Licenses = () => {

    const [allOwnedProducts, setAllOwnedProducts] = useState([]);
    const { user } = UserAuth();
    const navigate = useNavigate();

    //fetch purchased products
    useEffect(() => {
        if (user.uid) {
            const q = query(collection(db, "purchasedProducts"), where("user", "==", user.uid));

            onSnapshot(q, (snapshot) => {
                setAllOwnedProducts(snapshot.docs.map(doc => {
                    const productData = doc.data();
                    const availableLicenses = [productData.license1, productData.license2].filter(
                        (license) => license !== ""
                    ).length;

                    return {
                        id: doc.id,
                        viewing: false,
                        availableLicenses,
                        ...productData
                    }
                }))
            })
        }
    }, [user.uid])

    const newLicense = (_id) => {
        const product = allOwnedProducts.find(p => p.id === _id);
        if (product && product.availableLicenses < 2) {
            navigate(`/aurora/create/1/${_id}/${product.availableLicenses + 1}`);
        }
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const renderPurchasedProducts = allOwnedProducts.map((product) => {
        return (
            <div key={product.id} className="lic__prod" onClick={() => { newLicense(product.id) }}>
                <div className="lic__prodinfo">
                    <div className="lic__prodtext">
                        <h3>{capitalizeFirstLetter(product.name)}</h3>
                        <div className="lic__prodavailable">
                            <p>{product.availableLicenses}/2</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className='lic__container'>
            <div className="lic__bg">
                <img src={bgImg} alt="img" className='lic__imgsize' />
            </div>

            <div className="lic__content">

                <div className="lic__title">
                    Available licenses
                </div>

                <div className="lic__ontop">
                    {renderPurchasedProducts}
                </div>
            </div>

            <div className="lic__support">
                Sometimes licenses can take some time to appear because of transaction validation. If you have been experiencing that
                for some time you can contact customer support support@tapesignal.com and we'll get you sorted.
            </div>
        </div>
    )
}

export default Licenses
