import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { HmacSHA256, enc } from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

import { UserAuth } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import {
    serverTimestamp
} from "firebase/firestore"


const PaymentComponent = (props) => {
    const [data, setData] = useState(props.data);
    const [status, setStatus] = useState(null);
    const [checkoutId, setCheckoutId] = useState("")
    const [successHandlerCalled, setSuccessHandlerCalled] = useState(false);

    const { errorMessage, setErrorMessage } = props;

    const { user } = UserAuth();
    const { locationData, stateData } = UserAuth();

    const navigate = useNavigate()

    const billingDetails = props.billingDetails;

    const params = useParams();

    useEffect(() => {
        if (data) {
            console.log(data.body.data.id);
            setCheckoutId(data.body.data.id);
        }
    }, [data])

    useEffect(() => {
        console.log(status);
    }, [status])

    useEffect(() => {
        if (data) {
            const script = document.createElement('script');
            script.src = 'https://sandboxcheckouttoolkit.rapyd.net';
            script.async = true;

            script.onload = () => {
                if (typeof window.RapydCheckoutToolkit !== 'function') {
                    console.error("RapydCheckoutToolkit is not available");
                    return;
                }

                const checkoutInstance = new window.RapydCheckoutToolkit({
                    pay_button_text: "Buy Now",
                    pay_button_color: "#4BB4D2",
                    id: data.body.data.id,
                    style: {
                        submit: {
                            base: {
                                color: "white"
                            }
                        }
                    }
                });


                const successHandler = () => {
                    if (!successHandlerCalled) {
                        fetch(`https://rap-server-32a3v5w4sa-uc.a.run.app/payment-status/${user.uid}`)
                            .then(response => {
                                if (response.status === 201) {
                                    // If the response status is 201, redirect to the URL provided
                                    return response.text().then(redirectUrl => {
                                        window.location.href = redirectUrl;
                                    });
                                }
                                return response.json();
                            })
                            .then(data => {
                                if (data && data.status) {
                                    setSuccessHandlerCalled(true);
                                    navigate(`/thanksforpurchase/aurora`);
                                }
                            })
                            .catch(error => console.error('Error:', error));
                    }
                };




                const failureHandler = () => {
                    setErrorMessage("Internal Failure please try again or contact support");
                };

                const paymentFailureHandler = () => {
                    setErrorMessage("Payment Failure please try again or contact support");
                };

                window.addEventListener('onCheckoutPaymentSuccess', successHandler);
                window.addEventListener('onCheckoutFailure', failureHandler);
                window.addEventListener('onCheckoutPaymentFailure', paymentFailureHandler);

                checkoutInstance.displayCheckout();

                return () => {
                    window.removeEventListener('onCheckoutPaymentSuccess', successHandler);
                    window.removeEventListener('onCheckoutFailure', failureHandler);
                    window.removeEventListener('onCheckoutPaymentFailure', paymentFailureHandler);
                };
            }

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }
    }, [data]);




    return (
        <div className='checkout__container'>
            <div className="justify-content-center">
                <div className="col" id="rapyd-checkout"></div>
            </div>

            <div className="container">
                <div className="row justify-content-center text-center border-top py-2">
                    <div>
                        {errorMessage}
                    </div>
                    <span>&copy;Rapyd </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentComponent;
