import React from 'react'
import Header from '../header/Header'
import Homeinfo from '../homeinfo/Homeinfo'
import Salepage from '../salepage/Salepage'
import HomeScreen from '../homeinfo/HomeScreen'

const Home = () => {
  return (
    <div>
        <HomeScreen />
    </div>
  )
}

export default Home