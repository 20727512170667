import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { UserAuth } from '../context/AuthContext'; // Adjust this import path accordingly


const StateSelect = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const { setUserState } = UserAuth();

    const states = [
        { name: "Alabama", code: "AL" },
        { name: "Alaska", code: "AK" },
        { name: "Arizona", code: "AZ" },
        { name: "Arkansas", code: "AR" },
        { name: "California", code: "CA" },
        { name: "Colorado", code: "CO" },
        { name: "Connecticut", code: "CT" },
        { name: "Delaware", code: "DE" },
        { name: "Florida", code: "FL" },
        { name: "Georgia", code: "GA" },
        { name: "Hawaii", code: "HI" },
        { name: "Idaho", code: "ID" },
        { name: "Illinois", code: "IL" },
        { name: "Indiana", code: "IN" },
        { name: "Iowa", code: "IA" },
        { name: "Kansas", code: "KS" },
        { name: "Kentucky", code: "KY" },
        { name: "Louisiana", code: "LA" },
        { name: "Maine", code: "ME" },
        { name: "Maryland", code: "MD" },
        { name: "Massachusetts", code: "MA" },
        { name: "Michigan", code: "MI" },
        { name: "Minnesota", code: "MN" },
        { name: "Mississippi", code: "MS" },
        { name: "Missouri", code: "MO" },
        { name: "Montana", code: "MT" },
        { name: "Nebraska", code: "NE" },
        { name: "Nevada", code: "NV" },
        { name: "New Hampshire", code: "NH" },
        { name: "New Jersey", code: "NJ" },
        { name: "New Mexico", code: "NM" },
        { name: "New York", code: "NY" },
        { name: "North Carolina", code: "NC" },
        { name: "North Dakota", code: "ND" },
        { name: "Ohio", code: "OH" },
        { name: "Oklahoma", code: "OK" },
        { name: "Oregon", code: "OR" },
        { name: "Pennsylvania", code: "PA" },
        { name: "Rhode Island", code: "RI" },
        { name: "South Carolina", code: "SC" },
        { name: "South Dakota", code: "SD" },
        { name: "Tennessee", code: "TN" },
        { name: "Texas", code: "TX" },
        { name: "Utah", code: "UT" },
        { name: "Vermont", code: "VT" },
        { name: "Virginia", code: "VA" },
        { name: "Washington", code: "WA" },
        { name: "West Virginia", code: "WV" },
        { name: "Wisconsin", code: "WI" },
        { name: "Wyoming", code: "WY" },
        { name: "District of Columbia", code: "DC" },
        // For completeness, you may want to include these territories
        { name: "American Samoa", code: "AS" },
        { name: "Guam", code: "GU" },
        { name: "Northern Mariana Islands", code: "MP" },
        { name: "Puerto Rico", code: "PR" },
        { name: "United States Virgin Islands", code: "VI" }
    ];

    // Convert the options to the format needed by react-select
    const options = states.map(state => ({ value: state.code, label: `${state.name} - ${state.code}` }));


    const handleChange = (selectedOption) => {
        const selectedState = states.find(state => state.code === selectedOption.value);
        setUserState(selectedState.name, selectedState.code);
        setSelectedOption(selectedOption);
    };

    useEffect(() => {
        if (inputValue) {
            const option = options.find(option => option.label.toLowerCase() === inputValue.toLowerCase());
            if (option) {
                handleChange(option);
            }
        }
    }, [inputValue]);

    const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
    };

    const filterOption = (option, rawInput) => {
        const startsWithName = option.label.split(' - ')[0].toLowerCase().startsWith(rawInput.toLowerCase());
        const startsWithCode = option.label.split(' - ')[1].toLowerCase().startsWith(rawInput.toLowerCase());

        return startsWithName || startsWithCode;
    };

    return (
        <Select
            value={selectedOption}
            onChange={handleChange}
            onInputChange={handleInputChange}
            options={options}
            isSearchable
            filterOption={filterOption}
            placeholder="Select your state"
            className='country__size'
        />
    );
}

export default StateSelect;
