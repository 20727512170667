import React from 'react'
import Header from '../header/Header'
import { useNavigate } from 'react-router-dom'

const ContactThanks = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className='salepage__container'>
                <div className="salepage__img">
                    {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
                </div>
                <Header page="contact" />

                <div className="support__container">

                    <div className="support__thanks__container">
                        <div className="support__thanks">
                            Thank you for contacting our support team, we will try to respond to your
                            request as soon as possible.
                        </div>

                        <button onClick={() => {navigate('/products')}}>Browse Products</button>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default ContactThanks