import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import './HomeScreen.css';
import Header from '../header/Header';
import plugin from '../assets/AurFull.png'

import BagIconWhite from '../assets/CartBagWhite.svg'
import { ReactSVG } from 'react-svg'

//import bgVideoTest from '../assets/pexels-damiano-crognali-3910618-1920x1080-25fps.mp4'
//import bgVideoTest from '../assets/IMG_3276.MOV'
import bgVideoTest from '../assets/LandingPageTestVid2.mp4'

import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';
import CookieConsent from '../components/CookieConsent';


const HomeScreen = () => {
    const [selected, setSelected] = useState('aurora');

    const [scrolled, setScrolled] = useState(false);
    const scrollContainer = useRef(null);
    const scrolledContentRef = useRef(null);

    const navigate = useNavigate();



    // other component code

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        setScrolled(true);
                        setSelected('about aurora');
                    } else {
                        setScrolled(false);
                        setSelected('aurora');
                    }
                });
            },
            { threshold: 0 }
        );

        if (scrollContainer.current) {
            observer.observe(scrollContainer.current);
        }

        return () => {
            if (scrollContainer.current) {
                observer.unobserve(scrollContainer.current);
            }
        };
    }, []);

    const [clickedItem, setClickedItem] = useState('');




    const MenuItem = ({ text, onClick }) => {
        const isSelected =
            text.toLowerCase() === selected || text.toLowerCase() === clickedItem;
        return (
            <motion.div
                layout
                className={`menu-item ${isSelected ? 'selected' : ''}`}
                onClick={() => onClick(text.toLowerCase())}
            >
                <div className='menu-item-title'>
                    {text}
                </div>

                {/*<div className='menu-item-info'>
                    {text}
        </div>*/}

            </motion.div>
        );
    };

    const handleMenuClick = (text) => {
        setClickedItem(text);
        if (text === 'about aurora' && scrolledContentRef.current) {
            scrolledContentRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (text === 'aurora' && scrollContainer.current) {
            scrollContainer.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div className="home-screen">

            <div className="home__bg">

            </div>

            <motion.div>
                <video
                    className='home-video'
                    src={bgVideoTest}
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    playsInline={true}
                >
                </video>
            </motion.div>

            <div className="home-blurry"></div>
            <Header page="home" />
            <div className="content-container" ref={scrollContainer}>
                <div className="left-column">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.7 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.0 }}
                        className="home-title">
                        <div className="home-line-container">
                            {/*<div className="home-line"></div>
                            <div className="home-line"></div>
                            <div className="home-line"></div>
                            <div className="home-line"></div>
    <div className="home-line"></div>*/}
                        </div>
                        Aurora
                    </motion.div>
                    <motion.div
                        className="home-info"
                        initial={{ opacity: 0, scale: 0.7 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.1 }}
                    >
                        A new tape style delay from Tape Signal.
                        A one of a kind feedback eq that sparks new life into every sound you put through it.
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, scale: 0.7 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.2 }}>

                        <button className="shop-now-btn"
                            onClick={() => {
                                navigate('/aurora');
                                logEvent(analytics, "Home_Screen_Shop_Now")
                            }}
                        >
                            <ReactSVG src={BagIconWhite} />
                            <motion.div

                            >
                                Shop now
                            </motion.div>
                        </button>
                    </motion.div>
                </div>
                {/*<div className="middle-column">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.7 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.3 }}>
                        <img src={plugin} className='home-img'></img>
                    </motion.div>
</div>*/}
                {/*<div className="right-column">
                    <AnimateSharedLayout>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.7 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.0 }}>

                            <MenuItem text="Aurora" onClick={handleMenuClick} />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.7 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.1 }}>

                            <MenuItem text="About Aurora" onClick={handleMenuClick} />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.7 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.2 }}>

                            <MenuItem text="Watch Video" onClick={handleMenuClick} />
                        </motion.div>
                    </AnimateSharedLayout>
    </div>*/}
                <div className="bottom-left">Tape Signal ehf.</div>
                <div className="bottom-right"
                    onClick={() => {
                        logEvent(analytics, "Home_Contact")
                        navigate('/contact')
                    }}
                >Contact us.</div>
            </div>

            {/* <motion.div
                ref={scrolledContentRef}

                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                className="scrolled-content"
            >
                <div className="text-column left">
                    asdfæjasdfklæs
                </div>
                <div className="middle-column">
                    <button className="shop-now-btn"

                    >Shop now</button>
                </div>
                <div className="text-column right">
                    aksdfjælksadj
                </div>
</motion.div>*/}
        </div>
    );
};

export default HomeScreen;
