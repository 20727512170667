import React from 'react'
import Header from '../header/Header'
import SignIn from './SignIn'

const SignInPage = () => {
  return (
    <div>
        <Header />
        <SignIn isPlugin={false}/>
    </div>
  )
}

export default SignInPage