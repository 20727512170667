import React, { useState } from 'react';
import { db } from '../firebase';
import Header from '../header/Header';
import './contact.css'
import {
    doc,
    collection,
    onSnapshot,
    setDoc,
    deleteDoc,
    query,
    where,
    getDocs,
    orderBy,
    getDoc,
    serverTimestamp,
    FieldValue,
    arrayUnion,
    updateDoc
} from "firebase/firestore"
import OneCartItem from '../checkoutpage/OneCartItem';
import CheckoutTotal from '../checkoutpage/CheckoutTotal';
import CountrySelect from '../checkoutpage/CountrySelect';
import { useNavigate } from 'react-router-dom';

const MIN_TEXTAREA_HEIGHT = 10;

const ContactUsForm = () => {
    const [state, setState] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });


    const navigate = useNavigate();

    



    const supportCollectionRef = collection(db, "support");

    const saveToFirebase = event => {
        event.preventDefault();

        const newSupportClaim = {
            name: state.name,
            email: state.email,
            subject: state.subject,
            message: state.message
        }

        setDoc(doc(supportCollectionRef), newSupportClaim).then((value) => {
            navigate('/contact/thanks');
        });
    };





    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }



    return (
        <div className='salepage__container'>
            <div className="salepage__img">
                {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
            </div>
            <Header page="contact" />
            <div>
                <div className="support__container">
                    <div className="support__contentcontainer">
                        <div className='support__title'>Contact Us</div>
                        <form onSubmit={saveToFirebase} className='contact__formcontainer'>
                            <label>Name</label>
                            <input type="text" name="name" onChange={handleChange} />

                            <label>Email</label>
                            <input type="email" name="email" onChange={handleChange} />

                            <label>Subject</label>
                            <input type="text" name="subject" onChange={handleChange} />

                            <label>Message</label>
                            <textarea name="message" onChange={handleChange} />

                            <button type="submit">Send</button>
                        </form>
                    </div>

                </div>
                    <div className="support__info">
                        Your message will be recieved and answered via email from our
                        support staff at support@tapesignal.com. <br />
                        Feel free to also contact that email address directly.
                    </div>
            </div>
        </div>
    );
}

export default ContactUsForm;
