import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom';

const PluginFailed = () => {
    let params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.reload == 1) {
            navigate(`/plugin/${2}/failed`);
            window.location.reload();
        }
    }, [])
    
    return (
        <div>PluginFailed</div>
    )
}

export default PluginFailed