import React from 'react'

const CheckoutTotal = (props) => {
    const price = props.price;

    return (
        <div className='checkouttotal__container'>
            <div className="checkouttotal__line">

            </div>

            <div className="checkouttotal__flexcontainer">
                <div className="checkouttotal__title">
                    Total
                </div>

                <div className="checkouttotal__pricecontainer">
                    <div className="checkouttotal__currency">
                        USD
                    </div>

                    <div className="checkouttotal__price">
                        {price}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutTotal