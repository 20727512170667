import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom';

import { UserAuth } from '../context/AuthContext'


import { db } from "../firebase"
import {
  doc,
  collection,
  onSnapshot,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  orderBy,
  getDoc,
  serverTimestamp,
  FieldValue,
  arrayUnion,
  updateDoc
} from "firebase/firestore"

const CreateNewLic = () => {
  let params = useParams();
  const navigate = useNavigate()

  const [error, setError] = useState("");

  const { user } = UserAuth();
  const currentUrl = window.location.href;


  //breytir available lic í firebase og navigatear á /aurora/lic/:name/:machineid
  const updateLicense = async () => {
    let machineid = {
      license1: params.machineid,
    };

    console.log(params.numlic);

    if (params.numlic == 2) {
      machineid = {
        license2: params.machineid
      };
    }

    let responseData = "";

    try {
      const response = await fetch('https://ts-payment-l6tiifacuq-uc.a.run.app/getmessage', {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json'
        },
        body: JSON.stringify({
          data: params.machineid
        }),
      });

      if (!response.ok) {
        throw new Error('Server response wasnt OK');
      }


      responseData = await response.text();
    } catch (error) {
      console.error('Error making server call:', error);
    }

    console.log(responseData)

    if (params.numlic == 2) {
      machineid = {
        license2: responseData
      };
    }
    else {
      machineid = {
        license1: responseData
      };
    }

    await setDoc(doc(db, "purchasedProducts", params.licenseid), machineid, {
      merge: true,
    });

    navigate(`/aurora/lic/1/${responseData}/${user.email}`);
  };

  useEffect(() => {
    updateLicense().catch((error) => {
      setError(error.message);
      console.error("Error updating license:", error);
    });
  }, []);


  return (
    <div>
      <div className="salepage__img">
        {/* <img src={bgImg} alt="img" className='salepage__imgsize' />*/}
      </div>
      <div className="spinner">
        <div className="spinner-dot"></div>

      </div>
      <div>
        {error}
        {currentUrl}

      </div>
    </div>
  )
}

export default CreateNewLic